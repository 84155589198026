import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1eebee96 = () => interopDefault(import('../pages/chuyen-khoa/index.vue' /* webpackChunkName: "pages/chuyen-khoa/index" */))
const _552a81cd = () => interopDefault(import('../pages/dich-vu/index.vue' /* webpackChunkName: "pages/dich-vu/index" */))
const _b0410882 = () => interopDefault(import('../pages/gioi-thieu/index.vue' /* webpackChunkName: "pages/gioi-thieu/index" */))
const _1f60c8cf = () => interopDefault(import('../pages/lien-he/index.vue' /* webpackChunkName: "pages/lien-he/index" */))
const _56addc4e = () => interopDefault(import('../pages/tin-tuc/index.vue' /* webpackChunkName: "pages/tin-tuc/index" */))
const _c88f407e = () => interopDefault(import('../pages/tuyen-dung/index.vue' /* webpackChunkName: "pages/tuyen-dung/index" */))
const _0e9c9c1d = () => interopDefault(import('../pages/cham-soc-khach-hang/bao-hiem-y-te.vue' /* webpackChunkName: "pages/cham-soc-khach-hang/bao-hiem-y-te" */))
const _24db0c50 = () => interopDefault(import('../pages/cham-soc-khach-hang/cau-hoi-thuong-gap.vue' /* webpackChunkName: "pages/cham-soc-khach-hang/cau-hoi-thuong-gap" */))
const _73f7428a = () => interopDefault(import('../pages/cham-soc-khach-hang/dang-ky-kham-benh.vue' /* webpackChunkName: "pages/cham-soc-khach-hang/dang-ky-kham-benh" */))
const _e3aadb94 = () => interopDefault(import('../pages/gioi-thieu/doi-ngu-y-bac-si.vue' /* webpackChunkName: "pages/gioi-thieu/doi-ngu-y-bac-si" */))
const _05ceae8a = () => interopDefault(import('../pages/gioi-thieu/thu-vien-hinh-anh.vue' /* webpackChunkName: "pages/gioi-thieu/thu-vien-hinh-anh" */))
const _0ad3b681 = () => interopDefault(import('../pages/tin-tuc/_id.vue' /* webpackChunkName: "pages/tin-tuc/_id" */))
const _21ad5e69 = () => interopDefault(import('../pages/tuyen-dung/_id.vue' /* webpackChunkName: "pages/tuyen-dung/_id" */))
const _6407384c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7c869395 = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _7acff24d = () => interopDefault(import('../pages/_slug/_slug.vue' /* webpackChunkName: "pages/_slug/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/chuyen-khoa",
    component: _1eebee96,
    name: "chuyen-khoa"
  }, {
    path: "/dich-vu",
    component: _552a81cd,
    name: "dich-vu"
  }, {
    path: "/gioi-thieu",
    component: _b0410882,
    name: "gioi-thieu"
  }, {
    path: "/lien-he",
    component: _1f60c8cf,
    name: "lien-he"
  }, {
    path: "/tin-tuc",
    component: _56addc4e,
    name: "tin-tuc"
  }, {
    path: "/tuyen-dung",
    component: _c88f407e,
    name: "tuyen-dung"
  }, {
    path: "/cham-soc-khach-hang/bao-hiem-y-te",
    component: _0e9c9c1d,
    name: "cham-soc-khach-hang-bao-hiem-y-te"
  }, {
    path: "/cham-soc-khach-hang/cau-hoi-thuong-gap",
    component: _24db0c50,
    name: "cham-soc-khach-hang-cau-hoi-thuong-gap"
  }, {
    path: "/cham-soc-khach-hang/dang-ky-kham-benh",
    component: _73f7428a,
    name: "cham-soc-khach-hang-dang-ky-kham-benh"
  }, {
    path: "/gioi-thieu/doi-ngu-y-bac-si",
    component: _e3aadb94,
    name: "gioi-thieu-doi-ngu-y-bac-si"
  }, {
    path: "/gioi-thieu/thu-vien-hinh-anh",
    component: _05ceae8a,
    name: "gioi-thieu-thu-vien-hinh-anh"
  }, {
    path: "/tin-tuc/:id?",
    component: _0ad3b681,
    name: "tin-tuc-id"
  }, {
    path: "/tuyen-dung/:id?",
    component: _21ad5e69,
    name: "tuyen-dung-id"
  }, {
    path: "/",
    component: _6407384c,
    name: "index"
  }, {
    path: "/:slug",
    component: _7c869395,
    name: "slug"
  }, {
    path: "/:slug/:slug",
    component: _7acff24d,
    name: "slug-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
