import Introduction from '@/api/introduction';
import Faqs from '@/api/faqs';
import Posts from '@/api/posts';
import Fecruitments from '@/api/recruitments';
import Doctors from '@/api/doctors';
import Contacts from '@/api/contacts';
import Settings from '@/api/settings';
import Tickets from '@/api/tickets';

export default function (context, inject) {
    const factories = {
        introduction: Introduction(context.$axios),
        faqs: Faqs(context.$axios),
        posts: Posts(context.$axios),
        recruitments: Fecruitments(context.$axios),
        doctors: Doctors(context.$axios),
        contacts: Contacts(context.$axios),
        tickets: Tickets(context.$axios),
        settings: Settings(context.$axios),
    };

    inject('api', factories);
}
